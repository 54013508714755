import styled from "styled-components";
import {
  ChakrapetchBoldSilver65px,
  ChakrapetchBoldSilver45px,
  BaijamjureeBoldBlack25px,
  BaijamjureeBoldBlack12px,
  ChakrapetchSemiBoldWhite16px,
} from "../../styledMixins";
import "./DustNFTdesktop.css";
import React, { useState } from 'react'
import { ethers } from 'ethers'

function DustNFTdesktop(props) {
  const {
    commanderBrownThePoint1,
    shadingleft1,
    commanderBrownSingleHandKnife3,
    shadingright11,
    commanderBrownPistolGrip1,
    shadingleft2,
    floatingdale,
    commanderBrownMainPoseGld_Diamond1,
    shadingright12,
    mint,
    overlapGroup5,
    title,
    subtract1,
    button1,
    subtract2,
    subtract3,
    button2,
    subtract4,
    subtract5,
    number,
    subtract6,
    subtract7,
    button3,
    subtract8,
    address,
    team,
    commanderBrown,
    founder1,
    commanderBrownMainPoseGld_Diamond2,
    vector2,
    img_048811,
    mirela,
    founder2,
    vector3,
    lebdon1,
    lebdon2,
    coFounder1,
    overlapGroup21,
    sirAc_TheLegendCopy1,
    acLegend,
    coFounder2,
    vector4,
    waka1,
    waka,
    coFounderDev1,
    vector5,
    flip1,
    flip,
    coFounderDev2,
    overlapGroup22,
    img_04941,
    surname,
    communityManager,
    roadmap,
    about,
    overlapGroup,
    theW3CyberSecurity,
    iconTwitter,
    iconInstagram,
    iconYoutube,
    discord,
    dustlogo,
    shadingleft3,
    cyberSec1,
    roadmap11,
  } = props;

  const [defaultAccount, setDefaultAccount] = useState(null);
  const [connButtonText, setConnButtonText] = useState('Connect Wallet');


  const connectWalletHandler = () => {
    if (window.ethereum && window.ethereum.isMetaMask) {
      console.log('MetaMask Here!');

      window.ethereum.request({ method: 'eth_requestAccounts' })
        .then(result => {
          accountChangedHandler(result[0]);
          setConnButtonText('Wallet Connected');
          getAccountBalance(result[0]);
        })
        .catch(error => {
          setErrorMessage(error.message);
        });

    } else {
      console.log('Need to install MetaMask');
      var x = document.getElementById("snackbar");
      x.className = "show";
      setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);
    }
  }

  // update account, will cause component re-render
  const accountChangedHandler = (newAccount) => {
    if (window.ethereum && window.ethereum.isMetaMask) {
      setDefaultAccount(newAccount);
      getAccountBalance(newAccount.toString());
    }
  }

  const getAccountBalance = (account) => {
    if (window.ethereum && window.ethereum.isMetaMask) {
      window.ethereum.request({ method: 'eth_getBalance', params: [account, 'latest'] })
        .then(balance => {
          setUserBalance(ethers.utils.formatEther(balance));
        })
        .catch(error => {
          setErrorMessage(error.message);
        });
    }
  };

  const chainChangedHandler = () => {
    // reload the page to avoid any errors with chain change mid use of application
    if (window.ethereum && window.ethereum.isMetaMask) {
      window.location.reload();
    }
  }


  // listen for account changes
  if (window.ethereum && window.ethereum.isMetaMask) {
    window.ethereum.on('accountsChanged', accountChangedHandler);
  }

  if (window.ethereum && window.ethereum.isMetaMask) {
    window.ethereum.on('chainChanged', chainChangedHandler);
  }

  return (
    <div className="background">
      <div className="dustnftdesktop screen">
        <OverlapGroup7>
          <OverlapGroup6>
            <OverlapGroup4>
              <Background></Background>
              <OverlapGroup>
                <CommanderBrownThePoint1 src={commanderBrownThePoint1} />
                <ShadingLeft src={shadingleft1} />
              </OverlapGroup>
              <OverlapGroup1>
                <CommanderBrownSingleHandKnife3 src={commanderBrownSingleHandKnife3} />
                <ShadingRight1 src={shadingright11} />
              </OverlapGroup1>
              <OverlapGroup2>
                <CommanderBrownPistolGrip1 src={commanderBrownPistolGrip1} />
                <ShadingLeft1 src={shadingleft2} />
              </OverlapGroup2>
              <FloatingDale style={{ backgroundImage: `url(${floatingdale})` }}></FloatingDale>
              <OverlapGroup3>
                <CommanderBrownMainPoseGLDDiamond1 src={commanderBrownMainPoseGld_Diamond1} />
                <ShadingRight11 src={shadingright12} />
              </OverlapGroup3>
            </OverlapGroup4>
            <Mint>
              <MINT>{mint}</MINT>
              <OverlapGroup5 style={{ backgroundImage: `url(${overlapGroup5})` }}>
                <Title>{title}</Title>
                <Buttons>
                <Connect onClick={connectWalletHandler}>
                    <Subtract src={subtract1} />
                    <OverlapGroup8>
                      <Frame1>
                        <Button>{button1}</Button>
                      </Frame1>
                      <Subtract1 src={subtract2} />
                    </OverlapGroup8>
                  </Connect>
                  <Minus>
                    <Subtract2 src={subtract3} />
                    <OverlapGroup11>
                      <Frame11>
                        <Button1>{button2}</Button1>
                      </Frame11>
                      <Subtract3 src={subtract4} />
                    </OverlapGroup11>
                  </Minus>
                  <Amount>
                    <Subtract2 src={subtract5} />
                    <OverlapGroup21>
                      <Frame12>
                        <Number>{number}</Number>
                      </Frame12>
                      <Subtract4 src={subtract6} />
                    </OverlapGroup21>
                  </Amount>
                  <Plus>
                    <Subtract src={subtract7} />
                    <OverlapGroup31>
                      <Frame13>
                        <Button2>{button3}</Button2>
                      </Frame13>
                      <Subtract5 src={subtract8} />
                    </OverlapGroup31>
                  </Plus>
                </Buttons>
                <Address>{address}{defaultAccount}</Address>
              </OverlapGroup5>
              <div id="snackbar">PLEASE INSTALL METAMASK..</div>
            </Mint>
            <Team>
              <TEAM>{team}</TEAM>
              <TeamContainer>
                <Team1>
                  <CommanderBrown>
                    <OverlapGroup12>
                      <Vector src="/img/vector-5@2x.png" />
                      <COMMANDERBROWN>{commanderBrown}</COMMANDERBROWN>
                      <FOUNDER>{founder1}</FOUNDER>
                      <CommanderBrownMainPoseGLDDiamond2 src={commanderBrownMainPoseGld_Diamond2} />
                    </OverlapGroup12>
                  </CommanderBrown>
                  <Mirela>
                    <OverlapGroup9>
                      <Vector1 src={vector2} />
                      <IMG048811 src={img_048811} />
                      <MIRELA>{mirela}</MIRELA>
                      <FOUNDER1>{founder2}</FOUNDER1>
                    </OverlapGroup9>
                  </Mirela>
                  <Lebdon>
                    <OverlapGroup32>
                      <Vector src={vector3} />
                      <Lebdon1 src={lebdon1} />
                      <LEBDON>{lebdon2}</LEBDON>
                      <COFOUNDER>{coFounder1}</COFOUNDER>
                    </OverlapGroup32>
                  </Lebdon>
                  <AcLegend>
                    <OverlapGroup22 style={{ backgroundImage: `url(${overlapGroup21})` }}>
                      <SIRACTheLegendCopy1 src={sirAc_TheLegendCopy1} />
                      <ACLEGEND>{acLegend}</ACLEGEND>
                      <COFOUNDER1>{coFounder2}</COFOUNDER1>
                    </OverlapGroup22>
                  </AcLegend>
                </Team1>
                <Team2>
                  <OverlapGroup33>
                    <Waka>
                      <OverlapGroup10>
                        <Vector2 src={vector4} />
                        <Waka1 src={waka1} />
                        <WAKA>{waka}</WAKA>
                        <COFOUNDERDEV>{coFounderDev1}</COFOUNDERDEV>
                      </OverlapGroup10>
                    </Waka>
                    <Flip>
                      <OverlapGroup13>
                        <Vector3 src={vector5} />
                        <Flip1 src={flip1} />
                        <FLIP>{flip}</FLIP>
                        <COFOUNDERDEV1>{coFounderDev2}</COFOUNDERDEV1>
                      </OverlapGroup13>
                    </Flip>
                  </OverlapGroup33>
                  <CommunityManager>
                    <OverlapGroup23 style={{ backgroundImage: `url(${overlapGroup22})` }}>
                      <IMG04941 src={img_04941} />
                      <Surname>{surname}</Surname>
                      <COMMUNITYMANAGER>{communityManager}</COMMUNITYMANAGER>
                    </OverlapGroup23>
                  </CommunityManager>
                </Team2>
              </TeamContainer>
            </Team>
            <Roadmap>
              <ROADMAP>{roadmap}</ROADMAP>
            </Roadmap>
            <About>
              <ABOUT>{about}</ABOUT>
              <Group3>
                <OverlapGroup14 style={{ backgroundImage: `url(${overlapGroup})` }}>
                  <TheW3CyberSecurity>{theW3CyberSecurity}</TheW3CyberSecurity>
                </OverlapGroup14>
              </Group3>
            </About>
            <Header>
              <Socials>
                <a href="https://twitter.com/detroit_DUST" target="_blank">
                  <IconTwitter src={iconTwitter} />
                </a>
                <a href="https://www.instagram.com/detroit_d.u.s.t/" target="_blank">
                  <IconInstagram src={iconInstagram} />
                </a>
                <a href="https://www.youtube.com/channel/UCQa3HrGH-9mjz2Bn01Ve_iQ" target="_blank">
                  <IconInstagram src={iconYoutube} />
                </a>
                <a href="https://discord.gg/vavCcAN4Pv" target="_blank">
                  <IconInstagram src={discord} />
                </a>
                <a href="https://www.detroit-dust.com/" target="_blank">
                  <DustLogo src={dustlogo} />
                </a>
              </Socials>
            </Header>
          </OverlapGroup6>
          <ShadingLeft2 src={shadingleft3} />
          <CyberSec1 src={cyberSec1} />
          <ROADMAP11 src={roadmap11} />
        </OverlapGroup7>
      </div>
    </div>
  );
}

const OverlapGroup7 = styled.div`
  width: 1598px;
  height: 7050px;
  position: relative;
  margin-left: -198px;
`;

const OverlapGroup6 = styled.div`
  position: absolute;
  width: 1499px;
  height: 7050px;
  top: 0;
  left: 99px;
`;

const OverlapGroup4 = styled.div`
  position: absolute;
  width: 1499px;
  height: 7047px;
  top: 3px;
  left: 0;
`;

const Background = styled.div`
  position: absolute;
  width: 1400px;
  height: 7000px;
  top: 47px;
  left: 99px;
  background-color: var(--black);
`;

const OverlapGroup = styled.div`
  position: absolute;
  width: 863px;
  height: 1222px;
  top: 4847px;
  left: 0;
`;

const CommanderBrownThePoint1 = styled.img`
  position: absolute;
  width: 863px;
  height: 1209px;
  top: 0;
  left: 0;
  object-fit: cover;
`;

const ShadingLeft = styled.img`
  position: absolute;
  width: 668px;
  height: 1209px;
  top: 13px;
  left: 81px;
  object-fit: cover;
`;

const OverlapGroup1 = styled.div`
  position: absolute;
  width: 700px;
  height: 1496px;
  top: 3738px;
  left: 792px;
`;

const CommanderBrownSingleHandKnife3 = styled.img`
  position: absolute;
  width: 698px;
  height: 1406px;
  top: 0;
  left: 0;
`;

const ShadingRight1 = styled.img`
  position: absolute;
  width: 532px;
  height: 1406px;
  top: 90px;
  left: 168px;
  object-fit: cover;
`;

const OverlapGroup2 = styled.div`
  position: absolute;
  width: 514px;
  height: 1254px;
  top: 1917px;
  left: 86px;
`;

const CommanderBrownPistolGrip1 = styled.img`
  position: absolute;
  width: 508px;
  height: 1134px;
  top: 0;
  left: 0;
  object-fit: cover;
`;

const ShadingLeft1 = styled.img`
  position: absolute;
  width: 512px;
  height: 1134px;
  top: 120px;
  left: 2px;
  object-fit: cover;
`;

const FloatingDale = styled.div`
  position: absolute;
  width: 990px;
  height: 530px;
  top: 504px;
  left: 99px;
  background-size: cover;
  background-position: 50% 50%;
`;

const OverlapGroup3 = styled.div`
  position: absolute;
  width: 568px;
  height: 1563px;
  top: 0;
  left: 932px;
`;

const CommanderBrownMainPoseGLDDiamond1 = styled.img`
  position: absolute;
  width: 567px;
  height: 1500px;
  top: 63px;
  left: 0;
  object-fit: cover;
`;

const ShadingRight11 = styled.img`
  position: absolute;
  width: 567px;
  height: 1493px;
  top: 0;
  left: 0;
  object-fit: cover;
`;

const Mint = styled.div`
  position: absolute;
  width: 765px;
  top: 5050px;
  left: 602px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 459px;
`;

const MINT = styled.div`
  ${ChakrapetchBoldSilver45px}
  width: 119px;
  min-height: 59px;
  align-self: flex-end;
  margin-right: 6px;
  letter-spacing: 0;
`;

const OverlapGroup5 = styled.div`
  width: 746px;
  margin-left: -0.28px;
  display: flex;
  flex-direction: column;
  padding: 24px 38.7px;
  align-items: flex-start;
  min-height: 400px;
  background-size: cover;
  background-position: 50% 50%;
`;

const Title = styled.h1`
  width: 619px;
  min-height: 93px;
  align-self: flex-end;
  margin-top: 83px;
  font-family: var(--font-family-chakra_petch);
  font-weight: 700;
  color: #010101;
  font-size: var(--font-size-xl);
  letter-spacing: 0;
`;

const Buttons = styled.div`
  align-self: center;
  margin-top: 70px;
  margin-left: 1.55px;
  display: flex;
  align-items: flex-start;
  min-width: 451px;
`;

const Connect = styled.div`
  display: flex;
  align-items: flex-start;
  min-width: 153px;
  transition: all 0.2s ease;
  cursor: pointer;

  &:hover {
    transform: translate(0, -5px);
  }
`;

const Subtract = styled.img`
  width: 16px;
  height: 64px;
  object-fit: cover;
`;

const OverlapGroup8 = styled.div`
  width: 137px;
  height: 64px;
  position: relative;
`;

const Frame1 = styled.div`
  position: absolute;
  width: 121px;
  height: 64px;
  top: 0;
  left: 0;
  display: flex;
  background-color: var(--black);
`;

const Button = styled.div`
  ${ChakrapetchSemiBoldWhite16px}
  margin-top: 22px;
  width: 77px;
  height: 21px;
  margin-left: 22px;
  letter-spacing: 0.64px;
`;

const Subtract1 = styled.img`
  position: absolute;
  width: 16px;
  height: 64px;
  top: 0;
  left: 121px;
  object-fit: cover;
`;

const Minus = styled.div`
  margin-left: 10px;
  display: flex;
  align-items: flex-start;
  min-width: 87px;
  transition: all 0.2s ease;
  cursor: pointer;

  &:hover {
    transform: translate(0, -5px);
  }
`;

const Subtract2 = styled.img`
  width: 20px;
  height: 64px;
  object-fit: cover;
`;

const OverlapGroup11 = styled.div`
  width: 67px;
  height: 64px;
  position: relative;
`;

const Frame11 = styled.div`
  position: absolute;
  width: 51px;
  height: 64px;
  top: 0;
  left: 0;
  display: flex;
  background-color: var(--black);
`;

const Button1 = styled.div`
  ${ChakrapetchSemiBoldWhite16px}
  margin-top: 22px;
  width: 7px;
  height: 21px;
  margin-left: 22px;
  letter-spacing: 0.64px;
`;

const Subtract3 = styled.img`
  position: absolute;
  width: 16px;
  height: 64px;
  top: 0;
  left: 51px;
  object-fit: cover;
`;

const Amount = styled.div`
  margin-left: 10px;
  display: flex;
  align-items: flex-start;
  min-width: 95px;
`;

const OverlapGroup21 = styled.div`
  width: 75px;
  height: 64px;
  position: relative;
`;

const Frame12 = styled.div`
  position: absolute;
  width: 55px;
  height: 64px;
  top: 0;
  left: 0;
  display: flex;
  background-color: var(--black);
`;

const Number = styled.div`
  ${ChakrapetchSemiBoldWhite16px}
  margin-top: 22px;
  width: 11px;
  height: 21px;
  margin-left: 22px;
  letter-spacing: 0.64px;
`;

const Subtract4 = styled.img`
  position: absolute;
  width: 20px;
  height: 64px;
  top: 0;
  left: 55px;
  object-fit: cover;
`;

const Plus = styled.div`
  margin-left: 10px;
  display: flex;
  align-items: flex-start;
  min-width: 86px;
  transition: all 0.2s ease;
  cursor: pointer;

  &:hover {
    transform: translate(0, -5px);
  }
`;

const OverlapGroup31 = styled.div`
  width: 70px;
  height: 64px;
  position: relative;
`;

const Frame13 = styled.div`
  position: absolute;
  width: 54px;
  height: 64px;
  top: 0;
  left: 0;
  display: flex;
  background-color: var(--black);
`;

const Button2 = styled.div`
  margin-top: 22px;
  width: 10px;
  height: 21px;
  margin-left: 22px;
  font-family: var(--font-family-chakra_petch);
  font-weight: 700;
  color: var(--white);
  font-size: var(--font-size-xxs);
  letter-spacing: 0.64px;
`;

const Subtract5 = styled.img`
  position: absolute;
  width: 16px;
  height: 64px;
  top: 0;
  left: 54px;
  object-fit: cover;
`;

const Address = styled.div`
  width: 98px;
  min-height: 31px;
  margin-top: 11px;
  margin-left: 129.63px;
  font-family: var(--font-family-chakra_petch);
  font-weight: 600;
  color: var(--black);
  font-size: var(--font-size-xxs);
  letter-spacing: 0;
`;

const Team = styled.div`
  position: absolute;
  width: 877px;
  height: 572px;
  top: 4137px;
  left: 249px;
  display: flex;
  flex-direction: column;
`;

const TEAM = styled.div`
  ${ChakrapetchBoldSilver45px}
  margin-right: 733px;
  flex: 1;
  max-height: 35px;
  letter-spacing: 0;
`;

const TeamContainer = styled.div`
  margin-left: 0.2px;
  width: 874.75634765625px;
  margin-top: 21.1px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 516.3262939453125px;
`;

const Team1 = styled.div`
  display: flex;
  align-items: flex-start;
  min-width: 875px;
`;

const CommanderBrown = styled.div`
  align-self: flex-end;
  margin-bottom: 0;
  display: flex;
  align-items: flex-start;
  min-width: 206px;
  transition: all 0.2s ease;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }
`;

const OverlapGroup12 = styled.div`
  width: 202px;
  height: 258px;
  position: relative;
  margin-top: -0.31px;
`;

const Vector = styled.img`
  position: absolute;
  width: 202px;
  height: 247px;
  top: 0;
  left: 0;
  object-fit: cover;
`;

const COMMANDERBROWN = styled.div`
  ${BaijamjureeBoldBlack25px}
  position: absolute;
  width: 172px;
  top: 6px;
  left: 12px;
  letter-spacing: 0;
`;

const FOUNDER = styled.div`
  ${BaijamjureeBoldBlack12px}
  position: absolute;
  width: 172px;
  top: 64px;
  left: 12px;
  letter-spacing: 0;
`;

const CommanderBrownMainPoseGLDDiamond2 = styled.img`
  position: absolute;
  width: 194px;
  height: 219px;
  top: 27px;
  left: 8px;
`;

const Mirela = styled.div`
  margin-left: 12px;
  margin-top: 0.16px;
  display: flex;
  align-items: flex-start;
  min-width: 216px;
  transition: all 0.2s ease;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }
`;

const OverlapGroup9 = styled.div`
  width: 212px;
  height: 247px;
  position: relative;
  margin-top: -0.31px;
`;

const Vector1 = styled.img`
  position: absolute;
  width: 211px;
  height: 247px;
  top: 0;
  left: 0;
  object-fit: cover;
`;

const IMG048811 = styled.img`
  position: absolute;
  width: 212px;
  height: 223px;
  top: 24px;
  left: 1px;
`;

const MIRELA = styled.div`
  ${BaijamjureeBoldBlack25px}
  position: absolute;
  width: 172px;
  top: 14px;
  left: 12px;
  letter-spacing: 0;
`;

const FOUNDER1 = styled.div`
  ${BaijamjureeBoldBlack12px}
  position: absolute;
  width: 172px;
  top: 79px;
  left: 12px;
  letter-spacing: 0;
`;

const Lebdon = styled.div`
  margin-left: 10px;
  display: flex;
  align-items: flex-start;
  min-width: 207px;
  transition: all 0.2s ease;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }
`;

const OverlapGroup32 = styled.div`
  width: 203px;
  height: 247px;
  position: relative;
  margin-top: -0.06px;
`;

const Lebdon1 = styled.img`
  position: absolute;
  width: 203px;
  height: 220px;
  top: 27px;
  left: 0;
`;

const LEBDON = styled.div`
  position: absolute;
  width: 172px;
  top: 5px;
  left: 11px;
  font-family: var(--font-family-bai_jamjuree);
  font-weight: 700;
  color: var(--black);
  font-size: 20px;
  letter-spacing: 0;
`;

const COFOUNDER = styled.div`
  ${BaijamjureeBoldBlack12px}
  position: absolute;
  width: 172px;
  top: 70px;
  left: 12px;
  letter-spacing: 0;
`;

const AcLegend = styled.div`
  margin-left: 12px;
  display: flex;
  align-items: flex-start;
  min-width: 215px;
  transition: all 0.2s ease;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }
`;

const OverlapGroup22 = styled.div`
  width: 211px;
  height: 247px;
  position: relative;
  margin-top: -0.06px;
  background-size: cover;
  background-position: 50% 50%;
`;

const SIRACTheLegendCopy1 = styled.img`
  position: absolute;
  width: 210px;
  height: 224px;
  top: 23px;
  left: 0;
`;

const ACLEGEND = styled.div`
  ${BaijamjureeBoldBlack25px}
  position: absolute;
  width: 172px;
  top: 5px;
  left: 11px;
  letter-spacing: 0;
`;

const COFOUNDER1 = styled.div`
  ${BaijamjureeBoldBlack12px}
  position: absolute;
  width: 172px;
  top: 70px;
  left: 11px;
  letter-spacing: 0;
`;

const Team2 = styled.div`
  margin-top: 11px;
  margin-left: 5.63px;
  display: flex;
  align-items: flex-start;
  min-width: 697px;
`;

const OverlapGroup33 = styled.div`
  width: 468px;
  height: 247px;
  position: relative;
`;

const Waka = styled.div`
  position: absolute;
  height: 247px;
  top: 0;
  left: 225px;
  display: flex;
  align-items: flex-start;
  min-width: 243px;
  transition: all 0.2s ease;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }
`;

const OverlapGroup10 = styled.div`
  width: 239px;
  height: 247px;
  position: relative;
  margin-top: -0.06px;
`;

const Vector2 = styled.img`
  position: absolute;
  width: 210px;
  height: 247px;
  top: 0;
  left: 29px;
  object-fit: cover;
`;

const Waka1 = styled.img`
  position: absolute;
  width: 239px;
  height: 204px;
  top: 43px;
  left: 0;
`;

const WAKA = styled.div`
  ${BaijamjureeBoldBlack25px}
  position: absolute;
  width: 179px;
  top: 7px;
  left: 45px;
  letter-spacing: 0;
`;

const COFOUNDERDEV = styled.div`
  ${BaijamjureeBoldBlack12px}
  position: absolute;
  width: 179px;
  top: 73px;
  left: 45px;
  letter-spacing: 0;
`;

const Flip = styled.div`
  position: absolute;
  height: 247px;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
  min-width: 241px;
  transition: all 0.2s ease;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }
`;

const OverlapGroup13 = styled.div`
  width: 237px;
  height: 247px;
  position: relative;
  margin-top: -0.06px;
`;

const Vector3 = styled.img`
  position: absolute;
  width: 202px;
  height: 247px;
  top: 0;
  left: 35px;
  object-fit: cover;
`;

const Flip1 = styled.img`
  position: absolute;
  width: 236px;
  height: 217px;
  top: 30px;
  left: 0;
`;

const FLIP = styled.div`
  ${BaijamjureeBoldBlack25px}
  position: absolute;
  width: 172px;
  top: 4px;
  left: 45px;
  letter-spacing: 0;
`;

const COFOUNDERDEV1 = styled.div`
  ${BaijamjureeBoldBlack12px}
  position: absolute;
  width: 83px;
  top: 73px;
  left: 47px;
  letter-spacing: 0;
`;

const CommunityManager = styled.div`
  margin-left: 17px;
  display: flex;
  align-items: flex-start;
  min-width: 216px;
  transition: all 0.2s ease;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }
`;

const OverlapGroup23 = styled.div`
  width: 212px;
  height: 247px;
  position: relative;
  margin-top: -0.06px;
  background-size: cover;
  background-position: 50% 50%;
`;

const IMG04941 = styled.img`
  position: absolute;
  width: 209px;
  height: 210px;
  top: 36px;
  left: 3px;
`;

const Surname = styled.div`
  ${BaijamjureeBoldBlack25px}
  position: absolute;
  width: 179px;
  top: 9px;
  left: 13px;
  letter-spacing: 0;
`;

const COMMUNITYMANAGER = styled.div`
  ${BaijamjureeBoldBlack12px}
  position: absolute;
  width: 179px;
  top: 65px;
  left: 16px;
  letter-spacing: 0;
`;

const Roadmap = styled.div`
  position: absolute;
  width: 253px;
  height: 59px;
  top: 1556px;
  left: 1138px;
  display: flex;
`;

const ROADMAP = styled.div`
  ${ChakrapetchBoldSilver45px}
  height: 59px;
  margin-right: 2px;
  flex: 1;
  letter-spacing: 0;
`;

const About = styled.div`
  position: absolute;
  width: 1104px;
  height: 467px;
  top: 1037px;
  left: 252px;
  display: flex;
  flex-direction: column;
  transition: all 3s ease;
  cursor: pointer;
`;

const ABOUT = styled.div`
  ${ChakrapetchBoldSilver65px}
  margin-right: 920.2px;
  flex: 1;
  max-height: 25px;
  letter-spacing: 0;
`;

const Group3 = styled.div`
  margin-left: 1px;
  margin-right: 0;
  flex: 1;
  max-height: 400px;
  margin-top: 42px;
  display: flex;
  align-items: flex-start;
  min-width: calc(100% - 1px);
  transition: all 0.2s ease;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }
`;

const OverlapGroup14 = styled.div`
  height: 400px;
  display: flex;
  padding: 15px 15.8px;
  justify-content: flex-end;
  align-items: flex-start;
  min-width: 1101px;
  background-size: cover;
  background-position: 50% 50%;
`;

const TheW3CyberSecurity = styled.div`
  width: 1039px;
  min-height: 329px;
  font-family: var(--font-family-chakra_petch);
  font-weight: 400;
  color: var(--black);
  font-size: var(--font-size-l);
  letter-spacing: 0;
`;

const Header = styled.div`
  position: absolute;
  width: 1401px;
  height: 60px;
  top: 0;
  left: 99px;
  display: flex;
  justify-content: flex-end;
  background-color: var(--black);
`;

const Socials = styled.div`
  margin-top: 15px;
  width: 228px;
  margin-right: 153.8px;
  display: flex;
`;

const IconTwitter = styled.img`
  width: 32px;
  height: 32px;
  margin-left: -0.2px;
  transition: all 0.2s ease;
  cursor: pointer;

  &:hover {
    transform: translate(0, -5px);
  }
`;

const IconInstagram = styled.img`
  width: 32px;
  height: 32px;
  margin-left: 16px;
  transition: all 0.2s ease;
  cursor: pointer;

  &:hover {
    transform: translate(0, -5px);
  }
`;

const DustLogo = styled.img`
  width: 36px;
  height: 32px;
  margin-left: 16px;
  transition: all 0.2s ease;
  object-fit: cover;
  cursor: pointer;

  &:hover {
    transform: translate(0, -5px);
  }
`;

const ShadingLeft2 = styled.img`
  position: absolute;
  width: 533px;
  height: 530px;
  top: 507px;
  left: 198px;
  object-fit: cover;
`;

const CyberSec1 = styled.img`
  position: absolute;
  width: 542px;
  height: 511px;
  top: 0;
  left: 341px;
  object-fit: cover;
`;

const ROADMAP11 = styled.img`
  position: absolute;
  width: 1071px;
  height: 2500px;
  top: 1566px;
  left: 464px;
  object-fit: cover;
`;

export default DustNFTdesktop;
