
import React from "react";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import DustNFTdesktop from "./components/DustNFTdesktop";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/:path(|dustnftdesktop)">
          <DustNFTdesktop {...dustNFTdesktopData} />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
const dustNFTdesktopData = {
    commanderBrownThePoint1: "/img/commander-brown--the-point--1@1x.png",
    shadingleft1: "/img/shadingleft@1x.png",
    commanderBrownSingleHandKnife3: "/img/commander-brown--single-hand-knife--3@1x.png",
    shadingright11: "/img/shadingright1@1x.png",
    commanderBrownPistolGrip1: "/img/commander-brown--pistol-grip--1@1x.png",
    shadingleft2: "/img/shadingleft-1@1x.png",
    floatingdale: "https://cdn.animaapp.com/projects/623d37146653fe97efdb633b/files/nftrotation.gif",
    commanderBrownMainPoseGld_Diamond1: "/img/commander-brown--main-pose-gld-diamond-1@1x.png",
    shadingright12: "/img/shadingright1-1@1x.png",
    mint: "MINT",
    overlapGroup5: "/img/vector@1x.png",
    title: "COMING SOON...",
    subtract1: "/img/subtract@2x.png",
    button1: "CONNECT",
    subtract2: "/img/subtract-1@2x.png",
    subtract3: "/img/subtract-2@2x.png",
    button2: "-",
    subtract4: "/img/subtract-3@2x.png",
    subtract5: "/img/subtract-4@2x.png",
    number: "0",
    subtract6: "/img/subtract-5@2x.png",
    subtract7: "/img/subtract-6@2x.png",
    button3: "+",
    subtract8: "/img/subtract-7@2x.png",
    address: "Address:",
    team: "TEAM",
    commanderBrown: "COMMANDER BROWN",
    founder1: <><br />CO-FOUNDER</>,
    commanderBrownMainPoseGld_Diamond2: "/img/commander-brown--main-pose-gld-diamond-2@2x.png",
    vector2: "/img/vector-4@2x.png",
    img_048811: "/img/img-0488--1--1@2x.png",
    mirela: "MIRELA",
    founder2:  <>CO-FOUNDER</>,
    vector3: "/img/vector-7@2x.png",
    lebdon1: "/img/lebdon@2x.png",
    lebdon2: "LEBDON",
    coFounder1: "CO-FOUNDER",
    overlapGroup21: "/img/vector-6@2x.png",
    sirAc_TheLegendCopy1: "/img/sir-ac-the-legend-copy-1@2x.png",
    acLegend: "AC LEGEND",
    coFounder2: "CO-FOUNDER",
    vector4: "/img/vector-1@2x.png",
    waka1: "/img/waka-1@2x.png",
    waka: "WAKA",
    coFounderDev1: <>DEV</>,
    vector5: "/img/vector-2@2x.png",
    flip1: "/img/flip-1@2x.png",
    flip: "FLIP",
    coFounderDev2: <>DEV</>,
    overlapGroup22: "/img/vector-3@2x.png",
    img_04941: "/img/img-0494-1@2x.png",
    surname: "CASE",
    communityManager: <>COMMUNITY <br />MANAGER</>,
    roadmap: "ROADMAP",
    about: "ABOUT",
    overlapGroup: "/img/vector-8@1x.png",
    theW3CyberSecurity: "The W3 Cyber Security Task Force is the first Security platform on the block chain, providing security services, safety,  and survival education within the metaverse. We are developing a team of highly qualified characters to protect and serve with highly coveted assets and utilities through our NFTs. Join our discord to be an early adapter to this game-changing project.",
    iconTwitter: "/img/twitter@2x.png",
    iconInstagram: "/img/instagram@2x.png",
    iconYoutube: "/img/youtube@2x.png",
    discord: "/img/discord@2x.png",
    dustlogo: "/img/dustlogo@2x.png",
    shadingleft3: "/img/shadingleft-2@1x.png",
    cyberSec1: "/img/cyber-sec-1@1x.png",
    roadmap11: "/img/roadmap--1--1@1x.png",
};

