import { css } from "styled-components";

export const BaijamjureeBoldBlack12px = css`
  color: var(--black);
  font-family: var(--font-family-bai_jamjuree);
  font-size: var(--font-size-xxxs);
  font-weight: 700;
  font-style: normal;
`;

export const BaijamjureeBoldBlack25px = css`
  color: var(--black);
  font-family: var(--font-family-bai_jamjuree);
  font-size: var(--font-size-s);
  font-weight: 700;
  font-style: normal;
`;

export const ChakrapetchBoldSilver45px = css`
  color: var(--silver);
  font-family: var(--font-family-chakra_petch);
  font-size: var(--font-size-l);
  font-weight: 700;
  font-style: normal;
`;

export const ChakrapetchBoldSilver65px = css`
  color: var(--silver);
  font-family: var(--font-family-chakra_petch);
  font-size: var(--font-size-l);
  font-weight: 700;
  font-style: normal;
`;

export const ChakrapetchSemiBoldWhite16px = css`
  color: var(--white);
  font-family: var(--font-family-chakra_petch);
  font-size: var(--font-size-xxs);
  font-weight: 600;
  font-style: normal;
`;
